import { Link, navigate } from "gatsby";
import React, { useState, useRef, useEffect } from "react";
import { Container } from "../../styles/UI";
import Button from "../../DesignSystem/Button";
import Input from "../../DesignSystem/Input";
import ModalContato from "../ModalContato";

import LoginIcon from "../../icons/login.inline.svg";
import taxcelsummit from "../../images/TaxcelSummit.png";

import * as S from "./style";
import axios from "axios";

export default function Navbar(props) {
  const modalRef1 = useRef();

  const [isCollapsed, setisCollapsed] = useState(true);

  // const handleClickModal = () => {
  //   modalRef1.current.openModal();
  // };

  const [email, setEmail] = useState("");

  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const calculateTimeLeft = () => {
    const targetDate = new Date("2024-04-26T09:00:00-03:00");
    const now = new Date();

    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const handleClick = () => {
    const client = axios.create({
      baseURL: `https://taxcellicenseserver2.azurewebsites.net/`,
      headers: {
        "Content-Type": `application/x-www-form-urlencoded`,
      },
    });

    //integracao RD
    const form = new URLSearchParams();

    // let telNumber = tel.replace(/\D/g, "");

    // modalRef1.current.openModal();

    if (props.taxdashs) {
      form.append("source", "Testar-TaxDashs-Incompleto");
      form.append("email", email);

      client.post(`/RD_Station.aspx`, form).then((resp) => {
        console.log(resp);
      });

      if (typeof window !== "undefined")
        // window.location.href = `https://azuremarketplace.microsoft.com/pt-br/marketplace/apps/taxcel.taxdashs?tab=overview`;
      window.location.href = `https://taxcelcloud.com/teste?email=${email}`;
      return;
    }

    form.append("source", "Baixar-Teste-Incompleto");
    form.append("email", email);

    client.post(`/RD_Station.aspx`, form).then((resp) => {
      console.log(resp);
    });

    navigate("/baixar-taxsheets#formulario", { state: { email } });
  };

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setEmail(value);
  };

  return (
    <>
      {/* <S.Banner>
        <Container>
          <div className="bannerFlex">
            <img src={taxcelsummit} alt="" />
            <p>Garanta seu lugar no Taxcel Summit 2024!</p>
            <div className="tempo">
              <div className="bloco">
                <p className="grande">{timeLeft.days}</p>
                <p className="legenda">dias</p>
              </div>
              <p className="separador">:</p>
              <div className="bloco">
                <p className="grande">{timeLeft.hours}</p>
                <p className="legenda">horas</p>
              </div>
              <p className="separador">:</p>
              <div className="bloco">
                <p className="grande">{timeLeft.minutes}</p>
                <p className="legenda">min</p>
              </div>
              <p className="separador">:</p>
              <div className="bloco">
                <p className="grande">{timeLeft.seconds}</p>
                <p className="legenda">seg</p>
              </div>
            </div>
            <Button
                onClick={() => navigate("https://taxcelsummit.com.br/")}
                bg="primary"
                color="branco"
                className="demonstracao only-desktop"
                center
              >
                Quero participar!
              </Button>
          </div>
        </Container>
      </S.Banner> */}
      <S.Navbar>
        <ModalContato refModal={modalRef1} />
        <Container className="flex">
          <Link to="/">
            <img src="/logo.png" alt="logo Taxcel" />
          </Link>
          <S.MenuButton
            collapsed={isCollapsed}
            onClick={() => setisCollapsed(!isCollapsed)}
          >
            <span />
            <div>
              <span />
              <span />
            </div>
            <span />
          </S.MenuButton>
          <S.Navigation collapsed={isCollapsed}>
            <S.Links>
              <Link className="only-mobile" to="/">
                Home
              </Link>
              {/* <Link to="https://conteudo.taxcel.com.br/indique-ganhe">
                Indique e ganhe
              </Link> */}
              <div className="produtos">
                <Link
                  activeClassName="active"
                  id="produtos-navbar"
                  to="/produtos"
                >
                  Produtos
                </Link>
                <div className="produtos-menu">
                  <Link activeClassName="active" to="/taxdashs">
                    TaxDashs
                  </Link>
                  <Link activeClassName="active" to="/taxsheets">
                    TaxSheets
                  </Link>
                </div>
              </div>
              <Link activeClassName="active" to="/planos">
                Planos
              </Link>
              <Link activeClassName="active" to="/sobre">
                Sobre
              </Link>
              <Link activeClassName="active" to="/contato">
                Contato
                {/* <button onClick={handleClickModal}>Contato</button> */}
              </Link>
              {/* <Link activeClassName="active" to="/demonstracao">
              Demonstração
            </Link> */}
              <Button
                onClick={() => navigate("/demonstracao")}
                ghost
                bg="branco"
                color="branco"
                className="demonstracao only-desktop"
                size="small"
              >
                Agendar demonstração
              </Button>
            </S.Links>
            {/* {props.comprar ? (
            <Button
              onClick={() => navigate("/comprar")}
              bg="verde"
              color="primary"
              className="only-desktop"
            >
              Compre já
            </Button>
          ) : (
            <Input
              value={email}
              onChange={handleChange}
              placeholder="Seu email"
              className="only-desktop"
            >
              <Button onClick={handleClick}>Teste já</Button>
            </Input>
          )} */}
            {props.taxdashs ? (
              <Button
                onClick={handleClick}
                bg="taxdashs"
                color="textOnYellow"
                className="only-desktop teste"
              >
                Testar trial
              </Button>
            ) : (
              <Button
                onClick={handleClick}
                bg="secondary"
                color="primary"
                className="only-desktop teste"
              >
                Testar trial
              </Button>
            )}
            {props.taxdashs && (
              <S.Login href="https://taxcelcloud.com/">
                Log In <LoginIcon />
              </S.Login>
            )}
          </S.Navigation>
        </Container>
      </S.Navbar>
    </>
  );
}
